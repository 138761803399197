import clsx from 'clsx';
import debounce from 'debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ZendeskAPI } from 'react-zendesk';
import useAsyncEffect from 'use-async-effect';

import useAccessMetaverse from 'src/features/Visitor/hooks/useAccessMetaverse';
import { useMetaverseVisitor } from 'src/queries/metaverse';
import { selectMetaverseId } from 'src/redux/metaverse';

import { subject } from '@casl/ability';
import Action from 'src/casl/Action';
import { ReactComponent as PixelCanvasLogo } from '../../../../assets/svg/pixel-canvas-logo.svg';
import { ReactComponent as ChatsIcon } from '../../../../assets/svg/pixel-connect/chats.svg';
import { ReactComponent as CommunityIcon } from '../../../../assets/svg/pixel-connect/community.svg';
import { ReactComponent as CreateChannelIcon } from '../../../../assets/svg/pixel-connect/create a room.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/svg/pixel-connect/current call.svg';
import { ReactComponent as FAQIcon } from '../../../../assets/svg/pixel-connect/faq.svg';
import { ReactComponent as ProfileIcon } from '../../../../assets/svg/pixel-connect/profile.svg';
import { ReactComponent as DeviceSettingsIcon } from '../../../../assets/svg/pixel-connect/settings.svg';
import { ReactComponent as SignOutLogo } from '../../../../assets/svg/pixel-connect/sign out.svg';
import { ReactComponent as TechSupportIcon } from '../../../../assets/svg/question-circle.svg';
import usePxAuthContext from '../../../../authentication/usePxAuthContext';
import useSocketContext from '../../../../contexts/useRoomsSocketContext';
import { ModerationAction, ModerationRequest, PlatformRole, Room, RoomType, SocketEvent } from '../../../../interfaces';
import { selectSelectedTab, setSelectedTab, unsetSelectedTab } from '../../../../redux/pixelconnect';
import { getCustomMetaverseFields, getMetaverseVisitor } from '../../../../services/MetaverseService';
import { getMessagesBefore, getRecentMessages, getRoom, getRoomParticipants, getVideoToken, joinRoom } from '../../../../services/RoomService';
import { getDisplayName, getUserDisplayName } from '../../../../utils/DisplayNameUtil';
import CollapseButton from '../../../Visitor/components/CollapseButton/CollapseButton';
import ConfirmationModal from '../../../Visitor/components/ConfirmationModal/ConfirmationModal';
import ProfileModal, { ProfileModalInterface } from '../../../Visitor/components/ProfileModal/ProfileModal';
import ChatBubbles from '../ChatBubbles/ChatBubbles';
import ChatWindow from '../ChatWindow/ChatWindow';
import FAQModal, { FAQModalInterface } from '../FAQModal/FAQModal';
import NotificationBell from '../NotificationBell/NotificationBell';
import PxSidebar from '../PxSidebar/PxSidebar';
import SidebarButton from '../PxSidebar/SidebarButton';
import DeviceSelectionDialog from '../VideoChat/DeviceSelectionDialog/DeviceSelectionDialog';
import PresenterVideo from '../VideoChat/PresenterVideo/PresenterVideo';
import useVideoContext from '../VideoChat/useVideoContext';
import Sidepanel, { SidepanelContent } from './Sidepanel/Sidepanel';

import Subject from 'src/casl/Subject';
import styles from './PixelConnectOverlay.module.scss';

export default function PixelConnectOverlay() {
  const {
    socket, focusedTextChatRoomId, setFocusedTextChatRoomId, disconnectSocketRoom, hideRecentRoom, joinSocketRoom, currentChannel,
  } = useSocketContext();
  const {
    connectVideoChat, disposeRoom, videoRoom, presenter,
  } = useVideoContext();
  const { user, logout } = usePxAuthContext();

  const { metaverse, ability } = useAccessMetaverse();
  const { data: metaverseVisitor } = useMetaverseVisitor(metaverse?._id, user._id);

  const dispatch = useDispatch();
  const metaverseId = useSelector(selectMetaverseId, shallowEqual);
  const selectedTab = useSelector(selectSelectedTab, shallowEqual);
  const [currentRoom, setCurrentRoom] = useState<Room | null>(null);
  const [currentRoomName, setCurrentRoomName] = useState<string>('');
  const [deviceSelectionOpen, setDeviceSelectionOpen] = useState(false);
  const profileModalRef = useRef<ProfileModalInterface | null>(null);
  const faqModalRef = useRef<FAQModalInterface | null>(null);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (!metaverse?.settings.techSupportEnabled) return;
    ZendeskAPI('webWidget:on', 'close', () => ZendeskAPI('webWidget', 'hide'));
  }, [metaverse?.settings.techSupportEnabled]);

  useAsyncEffect(async () => {
    if (focusedTextChatRoomId) {
      const room: Room = (await getRoom(metaverseId, focusedTextChatRoomId)) as any;
      setCurrentRoom(room);
      if (room.type === RoomType.Direct) {
        const roomParticipants: any[] = await getRoomParticipants(metaverseId, room._id) as any;
        const otherParticipants = roomParticipants
          .filter((remoteUser: any) => remoteUser.userId !== user?.userId);
        const title = otherParticipants
          .map((remoteUser: any) => getDisplayName(remoteUser.participant)).join(', ');
        setCurrentRoomName(title);
      } else {
        setCurrentRoomName(room.name);
      }
    } else {
      setCurrentRoom(null);
    }
  }, [focusedTextChatRoomId]);

  useAsyncEffect(async () => {
    if (!metaverse || !metaverseVisitor || !user) return;
    try {
      const customFieldData = await getCustomMetaverseFields(metaverse._id);
      const hasRequiredFields = customFieldData.fields
        .filter((field) => field.required)
        .every((field) => metaverseVisitor.fields?.[field.name]);
      if (user.newProfile || !user.displayName || !hasRequiredFields) {
        profileModalRef.current?.open();
      }
    } catch (e) { }
  }, [metaverseVisitor, user]);

  // Sidebar
  const onSidebarTabClicked = (clickedTab: SidepanelContent) => {
    if (selectedTab === clickedTab) {
      dispatch(unsetSelectedTab());
    } else {
      dispatch(setSelectedTab(clickedTab));
    }
  };

  const onUnrealRoomSelected = async ({ roomId }: { roomId: string }) => {
    try {
      const channel = await joinRoom(metaverseId, roomId);
      if (currentChannel) disconnectSocketRoom(currentChannel._id);
      joinSocketRoom(channel);
      if (channel.capabilities.video) {
        disposeRoom();
        const videoToken = (await getVideoToken(metaverseId, channel._id) as any).accessToken;
        await connectVideoChat(channel._id, channel.name, videoToken);
        onSidebarTabClicked(SidepanelContent.VideoChatTab);
      }
    } catch (e) { }
  };

  const debouncedOnUnrealRoomSelected = useCallback(debounce(onUnrealRoomSelected, 2000), [socket, currentChannel, videoRoom, metaverseId]);

  const fetchRoom = (roomId: string) => getRoom(metaverseId, roomId);

  const onUnrealRoomExited = async ({ roomId }: { roomId: string }) => {
    try {
      const channel: Room = await fetchRoom(roomId) as any;
      if (currentChannel) disconnectSocketRoom(currentChannel._id);
      if (channel.capabilities.video) {
        disposeRoom();
        onSidebarTabClicked(SidepanelContent.ChannelListTab);
      }
    } catch (e) { }
  };

  const handleModerationRequest = async (request: ModerationRequest) => {
    switch (request.action) {
      case ModerationAction.RemoveUser:
        onSidebarTabClicked(SidepanelContent.ChannelListTab);
        break;
      case ModerationAction.BanUser:
        window.location.reload();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on(SocketEvent.UnrealRoomSelected, ({ roomId }: { roomId: string }) => debouncedOnUnrealRoomSelected({ roomId }));
      socket.on(SocketEvent.UnrealRoomExited, onUnrealRoomExited);
      socket.on(SocketEvent.ModerationEnforced, handleModerationRequest);
    }
    return () => {
      socket?.off(SocketEvent.UnrealRoomSelected);
      socket?.off(SocketEvent.UnrealRoomExited, onUnrealRoomExited);
      socket?.off(SocketEvent.ModerationEnforced, handleModerationRequest);
    };
  }, [socket, currentChannel, videoRoom]);

  // Chat Window
  const getUser = (userId: string) => getMetaverseVisitor(metaverseId, userId);

  const getMessages = (roomId: string) => getRecentMessages(metaverseId, roomId);

  const getPreviousMessages = (roomId: string, refMessageId: string) => getMessagesBefore(metaverseId, roomId, refMessageId);

  // Chat Bubbles
  const fetchRoomParticipants = (roomId: string) => getRoomParticipants(metaverseId, roomId);

  const onTechSupportClicked = () => {
    ZendeskAPI('webWidget', 'identify', {
      name: getUserDisplayName(user) || '',
      email: user.email || '',
    });
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'toggle');
  };

  if (!metaverse) return null;

  const iconColor = metaverse.ui.colors.pixelConnectIcon;
  const canEditMetaverse = ability.can(Action.Update, subject(Subject.Metaverse, { ...metaverse }));
  const canModerate = ability.can(Action.Manage, Subject.ModerationAction);
  const canCreatePublicRoom = ability.can(Action.Create, subject(Subject.Room, { metaverseId: metaverse._id, public: true }));

  const sidebarButtons = () => (
    <>
      <SidebarButton
        className={clsx({ [styles.selected]: selectedTab === SidepanelContent.NotificationsTab })}
        logo={<NotificationBell />}
        hoverLogo={<NotificationBell />}
        text=""
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        overlayTabSelected={selectedTab === SidepanelContent.NotificationsTab}
        onClick={() => onSidebarTabClicked(SidepanelContent.NotificationsTab)}
      />
      <SidebarButton
        logo={<ProfileIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        hoverLogo={<ProfileIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        text="PROFILE"
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        onClick={() => profileModalRef.current?.open()}
      />
      <SidebarButton
        className={clsx({ [styles.selected]: selectedTab === SidepanelContent.VideoChatTab, disabled: !videoRoom, online: !!videoRoom })}
        logo={<VideoIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        hoverLogo={<VideoIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        text="CURRENT CALL"
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        overlayTabSelected={selectedTab === SidepanelContent.VideoChatTab}
        onClick={() => { if (videoRoom) onSidebarTabClicked(SidepanelContent.VideoChatTab); }}
      />
      <SidebarButton
        className={clsx({ [styles.selected]: selectedTab === SidepanelContent.ChannelListTab })}
        logo={<ChatsIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        hoverLogo={<ChatsIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        text="CHATS"
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        overlayTabSelected={selectedTab === SidepanelContent.ChannelListTab}
        onClick={() => onSidebarTabClicked(SidepanelContent.ChannelListTab)}
      />
      {
        canModerate &&
        <SidebarButton
          className={clsx({ [styles.selected]: selectedTab === SidepanelContent.ContactListTab })}
          logo={<CommunityIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          hoverLogo={<CommunityIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          text="COMMUNITY"
          hoverTextColor="black"
          activeColor="black"
          navBarColor="#FFFFFF"
          selected={false}
          overlayTabSelected={selectedTab === SidepanelContent.ContactListTab}
          onClick={() => onSidebarTabClicked(SidepanelContent.ContactListTab)}
        />
      }
      {
        canCreatePublicRoom &&
        <SidebarButton
          className={clsx({ [styles.selected]: selectedTab === SidepanelContent.CreateChannelTab })}
          logo={<CreateChannelIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          hoverLogo={<CreateChannelIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          text="CREATE A ROOM"
          hoverTextColor="black"
          activeColor="black"
          navBarColor="#FFFFFF"
          selected={false}
          overlayTabSelected={selectedTab === SidepanelContent.CreateChannelTab}
          onClick={() => onSidebarTabClicked(SidepanelContent.CreateChannelTab)}
        />
      }
      {
        metaverse?.settings.faqEnabled &&
        <SidebarButton
          logo={<FAQIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          hoverLogo={<FAQIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          text="FAQ"
          hoverTextColor="black"
          activeColor="black"
          navBarColor="#FFFFFF"
          selected={false}
          onClick={() => faqModalRef.current?.open()}
        />
      }
      {
        metaverse?.settings.techSupportEnabled &&
        <SidebarButton
          logo={<TechSupportIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          hoverLogo={<TechSupportIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
          text="TECHNICAL SUPPORT"
          hoverTextColor="black"
          activeColor="black"
          navBarColor="#FFFFFF"
          selected={false}
          onClick={() => onTechSupportClicked()}
        />
      }
      <SidebarButton
        logo={<DeviceSettingsIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        hoverLogo={<DeviceSettingsIcon className={styles['svg-logo']} style={{ fill: iconColor }} />}
        text="DEVICE SETTINGS"
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        onClick={() => setDeviceSelectionOpen(true)}
      />
      <SidebarButton
        logo={<SignOutLogo className={styles['svg-logo']} style={{ fill: iconColor }} />}
        hoverLogo={<SignOutLogo className={styles['svg-logo']} style={{ fill: iconColor }} />}
        text="SIGN OUT"
        hoverTextColor="black"
        activeColor="black"
        navBarColor="#FFFFFF"
        selected={false}
        onClick={() => setShowSignOutModal(true)}
      />
    </>
  );

  return (
    <div className={styles.container}>
      {
        user && metaverseVisitor?.userId &&
        <div className={clsx(styles['overlay-components'], { [styles['no-sidepanel']]: !selectedTab })}>
          {
            presenter && selectedTab === SidepanelContent.VideoChatTab &&
            <div className={styles['presenter-view']}>
              <PresenterVideo participant={presenter} getUser={getUser} />
            </div>
          }
          {
            currentRoom && currentRoom.capabilities.text &&
            <div className={styles['overlay-chat-window']}>
              <ChatWindow
                key={currentRoom._id}
                title={currentRoomName}
                roles={metaverseVisitor.roles}
                userInfo={user}
                getPreviousMessages={getPreviousMessages}
                getMessages={getMessages}
                getUser={getUser}
                onExitChat={() => {
                  if (currentRoom.type !== RoomType.Direct &&
                    currentRoom.capabilities.text &&
                    !currentRoom.capabilities.video
                  ) {
                    disconnectSocketRoom(currentRoom._id);
                  } else {
                    hideRecentRoom(currentRoom._id);
                  }
                }}
                onMinimizeChat={() => setFocusedTextChatRoomId(undefined)}
                room={currentRoom}
                getRoomParticipants={fetchRoomParticipants}
              />
            </div>
          }
          <div className={styles['overlay-chat-bubbles']}>
            <ChatBubbles
              onRoomClicked={() => { }}
              getRoom={fetchRoom}
              getRoomParticipants={fetchRoomParticipants}
            />
          </div>
          <div className={clsx(styles['overlay-side-panel'], { [styles.visible]: selectedTab })}>
            <Sidepanel
              isAdministrator={canEditMetaverse}
              roles={metaverseVisitor.roles}
              localUser={user}
            />
          </div>
        </div>
      }
      <PixelCanvasLogo className={styles['pixel-canvas-logo']} />
      <PxSidebar className={clsx(styles.sidebar, { [styles.collapsed]: collapsed })} width={100} background="white" sidebarButtons={sidebarButtons()} />
      <ProfileModal
        ref={profileModalRef}
      />
      <ConfirmationModal
        title="Sign Out"
        instructions1="Are you sure you want to sign out?"
        open={showSignOutModal}
        onClose={() => setShowSignOutModal(false)}
        confirmText="Sign Out"
        onConfirm={() => logout({ returnTo: `${window.location.origin}/landing` })}
        onCancel={() => setShowSignOutModal(false)}
      />
      {metaverse?.settings.faqEnabled && <FAQModal ref={faqModalRef} />}
      {
        deviceSelectionOpen &&
        <DeviceSelectionDialog open={deviceSelectionOpen} setOpen={setDeviceSelectionOpen} />
      }
      <CollapseButton
        className={clsx(styles.collapseButton, { [styles.collapsed]: collapsed })}
        direction="right"
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      />
    </div>
  );
}
